@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  backgroundColor: 'white';
  font-family: 'Outfit', sans-serif;
}

ul {
  list-style-type: none;
  
}
